<template>
  <div id="homeNav">
    <v-app-bar app hide-on-scroll color="light-blue lighten-4">
      <v-app-bar-nav-icon
        v-if="isMobile"
        @click="drawer = !drawer"
      ></v-app-bar-nav-icon>
      <div class="mr-3" v-if="isMobile === false">
        <v-avatar>
          <v-img src="@/assets/logo_kedas.png"></v-img>
        </v-avatar>
      </div>
      <v-app-bar-title>
        <span>Kedas Beauty</span>
      </v-app-bar-title>
      <v-spacer></v-spacer>
      <div class="fill-height" v-if="isMobile === false">
        <v-btn
          v-for="(menu, i) in menus"
          :key="i"
          text
          height="100%"
          :to="menu.to"
          active-class="blue lighten-2 white--text"
          class="ml-2"
          >{{ menu.text }}</v-btn
        >
      </div>
    </v-app-bar>
    <v-navigation-drawer
      app
      v-model="drawer"
      temporary
      color="light-blue accent-1"
      height="100%"
    >
      <v-row class="my-3">
        <v-col class="d-flex justify-center">
          <v-avatar class="mx-3">
            <v-img src="@/assets/logo_kedas.png"></v-img>
          </v-avatar>
          <span class="align-self-center white--text">Kedas Beauty</span>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row class="mt-3">
        <v-col class="py-0">
          <p class="text-center white--text">Menus</p>
        </v-col>
      </v-row>
      <v-row>
          <v-col class="py-0">
              <v-list dense rounded>
                  <v-list-item v-for="(menu, i) in menus" :key="i" link :to="menu.to" active-class="light-blue accent-2 white--text">
                      <v-list-item-content>
                          <v-list-item-title class="text-center white--text"><span class="text-body-1">{{menu.text}}</span></v-list-item-title>
                      </v-list-item-content>
                  </v-list-item>
              </v-list>
          </v-col>
      </v-row>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  name: "HomeNav",
  data() {
    return {
      drawer: false,
      isMobile: false,
      menus: [
        { text: "Home", to: "/" },
        { text: "Blog", to: "/blog" },
        { text: "About Us", to: "/about" },
      ],
    };
  },
  methods: {
    checkClientDevice(vw) {
      if (vw <= 522) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
      console.log(this.isMobile);
    },
  },
  computed: {
    vwVal() {
      return console.log(window.innerWidth);
    },
  },
  created() {
    if (window.innerWidth <= 522) {
      this.isMobile = true;
    }
    console.log(this.isMobile);
  },
};
</script>