<template>
  <div class="about fill-height" style="background-color: #ceeaf6">
    <home-nav></home-nav>
    <v-container class="justify-center fill-height">
      <v-row>
        <v-col cols="12" md="6" lg="6" xl="6" class="d-flex justify-center">
          <v-card rounded="lg">
            <v-img src="@/assets/landing_page/owner_.png"></v-img>
          </v-card>
        </v-col>
        <v-col cols="12" md="6" lg="6" xl="6">
          <v-row no-gutters class="align-center">
            <v-col>
              <h1 class="blue-grey--text">About Us</h1>
              <v-divider></v-divider>
              <h3 class="font-weight-normal mt-5">
                Kedas Beauty
              </h3>
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Sapiente voluptatem quos aut hic voluptates! Fugiat, vero ducimus temporibus, ea placeat unde laboriosam animi consectetur debitis vitae beatae in quos porro!</p>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <v-row>
      <v-footer dark padless style="width: 100%">
        <v-card
          flat
          tile
          class="kb-footer white--text text-center pa-5"
          width="100%"
        >
          <v-card-title class="white--text pt-0">
            <v-col cols="12" md="6" lg="6" xl="6">
              <h3>Get connected with us on our social networks!</h3>
            </v-col>
            <v-divider vertical></v-divider>
            <v-col cols="12" md="6" lg="6" xl="6">
              <v-tooltip bottom v-for="(sn, i) in social_networks" :key="i">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    outlined
                    x-large
                    :color="sn.color"
                    :href="sn.url"
                    dark
                    v-bind="attrs"
                    v-on="on"
                    target="_blank"
                    class="mx-3"
                  >
                    <v-icon>{{ sn.icon }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ sn.tooltip_text }}</span>
              </v-tooltip>
            </v-col>

            <v-spacer></v-spacer>
          </v-card-title>

          <v-divider></v-divider>

          <v-card-text class="white--text">
            Copyright © {{ new Date().getFullYear() }} KEDAS BEAUTY. All rights
            reserved |
            <strong>PT. MAHAPURNA MANDIRI UTAMA</strong>
          </v-card-text>
        </v-card>
      </v-footer>
    </v-row>
  </div>
</template>
<script>
import HomeNav from "../components/HomeNav.vue";
export default {
  data() {
    return {
      social_networks: [
        {
          icon: "mdi-instagram",
          url: "https://www.instagram.com/candradewimaharani_/",
          tooltip_text: "Instagram",
          color: "pink accent-3",
        },
        {
          icon: "mdi-music-circle-outline",
          url: "https://www.tiktok.com/@ceokedasbeauty",
          tooltip_text: "TikTok",
          color: "black",
        },
        {
          icon: "mdi-youtube",
          url: "https://www.youtube.com/c/CandraDewiFikaOfficial",
          tooltip_text: "YouTube",
          color: "red",
        },
      ],
    };
  },
  components: {
    HomeNav,
  },
  created() {
    console.log(window.innerWidth);
  },
};
</script>
<style>
.kb-footer {
  background-image: linear-gradient(180deg, #67c3f3, #5a98f2);
}
</style>